import {
  TLoginAuto,
  TLoginLpoint,
  TLoginSignUp,
} from 'src/containers/AutoLogin/types';
import api from 'src/utils/api';
import {
  bridgeDeleteCache,
  bridgeLogout,
  bridgeSetLoginInfo,
} from 'src/utils/bridge';
import { E_API_SERVICEID } from 'src/utils/enum';
import { getMembersTkn, storageMartgoTkn } from 'src/utils/sso';

/**
 * @name 자동 로그인
 */
export const apiUserSsoAutoLogin = async (params: TLoginAuto) => {
  const response = await api.post(
    `${process.env.REACT_APP_BASE_API_MARTGO}/fo/user/ssobottle/login/auto-sso`,
    params,
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 마트GO 자체 자동 로그인
 */
export const apiUserSsoAutoLoginGo = async (params: TLoginAuto) => {
  const response = await api.post(
    `${process.env.REACT_APP_BASE_API_MARTGO}/fo/user/ssobottle/login/auto-bt`,
    params,
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 마트GO 만료 토큰 갱신
 */
let isRefreshTokenProcess = false;
export const apiUserSsoTokenRefresh = async () => {
  const response = await api
    .get('/tokens/token-refresh', {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    })
    .catch((error: any) => {
      if (isRefreshTokenProcess) return;
      const status = error.response.status;

      if (status === 401) {
        isRefreshTokenProcess = true;
        //갱신토큰 만료 시 로그아웃 처리 및 로그인 페이지로 이동
        alert('로그인 정보가 만료되었습니다. 다시 로그인해주세요.');

        bridgeLogout();
        bridgeDeleteCache('');
        localStorage.clear();
        sessionStorage.clear();
        location.href = '/login';
      }
    });
  const data = await response?.data;

  const martGoTkn = {
    access_token: data.token,
    refresh_token: data.refreshToken,
  };
  const membersTkn = getMembersTkn();

  if (martGoTkn.access_token) {
    //마트GO 토큰 저장
    storageMartgoTkn(martGoTkn);
    bridgeSetLoginInfo(
      martGoTkn.access_token,
      martGoTkn.refresh_token,
      (membersTkn && membersTkn.acesTkn) || '',
      (membersTkn && membersTkn.rnwTkn) || '',
      (membersTkn && membersTkn.ssoTkn) || '',
    );
  }

  return data;
};
// https://dev.lottemartgo.com/apis/fo/user/ssobottle/login/lpoint
/**
 * @name 보틀벙커로그인
 */
export const apiUserSsoLogin = async (params: TLoginLpoint) => {
  const response = await api.post(
    `${process.env.REACT_APP_BASE_API_MARTGO}/fo/user/ssobottle/login/lpoint`,
    params,
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
      //headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.BOTTLE },
    },
  );
  const data = await response.data;
  return data;
};

/**
 * @name 엘포인트 로그인
 */
export const apiUserSsoLpointLogin = async (params: TLoginLpoint) => {
  const response = await api.post('/fo/user/sso/login/lpoint', params, {
    headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
  });
  const data = await response.data;
  return data;
};

/**
 * @name 롯데멤버스 선택약관 동의 여부 조회
 */
export const apiUserSsoAgmt = async params => {
  const response = await api.post(
    `${process.env.REACT_APP_BASE_API_MARTGO}/fo/user/sso/signup/sso-agmt`,
    params,
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 마트GO 가입 약관목록 조회
 */
export const apiUserSsoSignupAgmtList = async () => {
  const response = await api.post(
    `${process.env.REACT_APP_BASE_API_MARTGO}/fo/user/sso/signup/agmt/list`,
    {},
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 마트GO 약관동의 가입
 */
export const apiUserSsoSignUp = async (params: TLoginSignUp) => {
  const response = await api.post(
    `${process.env.REACT_APP_BASE_API_MARTGO}/fo/user/sso/signup`,
    params,
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );

  return response.data;
};

/**
 * @name 보틀벙커 약관목록 조회
 */
export const apiUserSsoBottleAgmtList = async () => {
  const response = await api.post(
    `${process.env.REACT_APP_BASE_API_MARTGO}/fo/user/ssobottle/agmt/list`,
    {},
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 보틀벙커 회원가입
 */
export const apiUserSsoBottleSignUp = async params => {
  const response = await api.post(
    `${process.env.REACT_APP_BASE_API_MARTGO}/fo/user/ssobottle/signup`,
    { ...params },
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 설정 마트GO/보틀벙커 회원여부 조회
 */
export const apiUserIsMember = async () => {
  const response = await api.post(
    `${process.env.REACT_APP_BASE_API_MARTGO}/fo/user/ssobottle/ismember`,
    {},
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 설정 보틀벙커 회원 탈퇴
 */
export const apiUserWithdraw = async () => {
  const response = await api.post(
    `${process.env.REACT_APP_BASE_API_MARTGO}/fo/user/ssobottle/withdraw`,
    {},
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 설정 로그아웃
 */
export const apiUserLogout = async () => {
  const response = await api.post(
    `${process.env.REACT_APP_BASE_API_MARTGO}/fo/user/ssobottle/logout`,
    {},
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data;

  return data;
};
