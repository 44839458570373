import React from 'react';
import { StyledConfirmPopupBanner } from '../styles';

type Props = {
  bannerChildren: React.ReactElement;
};
/**
 * @name 확인/취소 팝업 배너 컴포넌트
 */
export function ConfirmPopupBanner(props: Props): React.ReactElement {
  return (
    <StyledConfirmPopupBanner>
      <>{props.bannerChildren}</>
    </StyledConfirmPopupBanner>
  );
}
