import ReactGA from 'react-ga4';

/**
 * @description GA 태깅 - 페이지뷰
 * @param title 페이지명 (각 페이지에서 호출 시 필수)
 * @param pathname location.pathname
 */

type TGAPageview = {
  title: string;
  pathname: string;
};

export const gaPageView = ({ title, pathname }: TGAPageview): void => {
  ReactGA.send({
    hitType: 'pageview',
    title: title,
    page: pathname,
  });
};

/**
 * @description GA 태깅 - 이벤트
 * @param category 카테고리
 * @param action 액션
 * @param label 라벨
 */
type TGAEvent = {
  category: string;
  action: string;
  label: string;
};

export const gaEvent = ({ category, action, label }: TGAEvent): void => {
  //console.log(`category : ${category}, action : ${action}`);
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};
