import React, { useRef, useEffect } from 'react';

import { IMAGE_ROOT_BOTTLE } from 'src/utils/constants';
import {
  bridgeHideSplash,
  bridgeOpenAppSchemeStore,
  bridgeQuit,
} from 'src/utils/bridge';
import { Button } from 'src/components/Button';
import { StyledUpdate } from './styles';
//import { gaEvent } from 'src/utils/ga';

/**
 * @name 필수업데이트 페이지
 * @returns
 */
function SystemUpdate(): React.ReactElement {
  const imgRef = useRef<HTMLImageElement>(null);

  const fnErrorImage = () => {
    //배경이미지 없거나 에러인 경우 기본 스플래시 이미지
    imgRef.current?.setAttribute(
      'src',
      `${IMAGE_ROOT_BOTTLE}/system/app_update.png`,
    );
  };

  // 취소 클릭 - 앱 종료
  const fnCancel = () => {
    // gaEvent(`취소`, '', '');
    bridgeQuit();
  };

  // 업데이트 클릭 - 스토어로 이동
  const fnUpdate = () => {
    // gaEvent(`업데이트`, '', '');
    bridgeOpenAppSchemeStore();
  };

  useEffect(() => {
    bridgeHideSplash();
  }, []);

  return (
    <StyledUpdate>
      <img
        src={`${IMAGE_ROOT_BOTTLE}/system/app_update.png`}
        alt="필수업데이트 안내"
        onError={fnErrorImage}
        ref={imgRef}
      />
      <div className="btn">
        <Button
          text="취소"
          variant="contained"
          buttonColor="gray"
          onClick={fnCancel}
        />
        <Button
          text="업데이트"
          variant="contained"
          buttonColor="bottle_green"
          onClick={fnUpdate}
        />
      </div>
    </StyledUpdate>
  );
}

export default SystemUpdate;
