import { getUA } from 'react-device-detect';

export const IMAGE_ROOT = `${process.env.REACT_APP_BASE_S3_IMAGE}/app`;
export const IMAGE_ROOT_BOTTLE = `${process.env.REACT_APP_BASE_S3_IMAGE_BOTTLE}/app/images`;

export const ICON_PATH = `${process.env.REACT_APP_BASE_S3_IMAGE}/app/icons`;

export const ICON_ROOT_BOTTLE = `${process.env.REACT_APP_BASE_S3_IMAGE_BOTTLE}/app/icons`;

export const IS_BOTTLE_APP = getUA.indexOf('_BottleBunker') > -1;

//local 테스트 용 기기정보 하드코딩
export const LOCAL_DEVICE_INFO = {
  device_id: '(null)',
  device_os: 'pc',
  model_nm: '',
  app_ver: '1.0.0',
  wdata: '',
  ukey: '',
};

export const IS_PINCH_ZOOM_LIST = ['/product/findplace'];
