import { UserAction } from '../actions/userActions';
import ActionTypes from '../constants/userConstats';
import { TUserState } from '../types/userTypes';

export const initialState: TUserState = {
  userInfoState: {
    user_name: '',
    join_date: '',
    def_str_cd: '',
    def_str_nm: '',
    psp_id: '',
  },
  userAppCardInfoState: {
    membs_card_no: '',
  },
  currenTime: '',
};

function UserReducer(
  state: TUserState = initialState,
  action: UserAction,
): TUserState {
  switch (action.type) {
    case ActionTypes.SET_USERINFO_STATE:
      return {
        ...state,
        userInfoState: action.payload.userInfoState,
      };
    case ActionTypes.SET_CURRENT_TIME:
      return {
        ...state,
        currenTime: action.payload.currentTime,
      };
    default:
      return state;
  }
}

export default UserReducer;
