import React, { useEffect, useState } from 'react';
import Dim from '../Dim';
import CenteredPopupWrapper from './components/CenteredPopupWrapper';
import { E_POPUP_STATE } from 'src/utils/enum';
import { Button } from '../Button';
import { useDispatch } from 'react-redux';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { apiGetStoreList, apiSetChangeMainStore } from 'src/api/apiUser';
import {
  actionCloseAlertPopup,
  actionCloseMartSettingPopup,
  actionOpenAlertPopup,
} from 'src/redux/actions/appActions';
import { IMAGE_ROOT_BOTTLE } from 'src/utils/constants';
import { StyledMartSettingPopup } from './styles';
// import { gaEvent, gaPageView } from 'src/utils/ga';
import { TStore } from 'src/types/types';
import { PopupHeaderOnlyClose } from './components/PopupHeader';
import useSelectorEqual from 'src/hooks/useSelectorEqual';
import { RootState } from 'src/store/reducers';
import { TMartSettingPopupProps } from 'src/redux/types/appTypes';
import { gaEvent, gaPageView } from 'src/utils/ga';

function MartSettingPopup(props: {
  martSettingPopupState: TMartSettingPopupProps;
}): React.ReactElement {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const [userInfoState] = useSelectorEqual((state: RootState) => [
    state.user.userInfoState,
  ]);

  const [selectedStore, setSelectedStore] = useState<TStore>();

  const { data, isSuccess } = useQuery<{ str_list: TStore[] }>(
    ['martSettingPopup', 'storeList'],
    apiGetStoreList,
    {
      //suspense: true,
    },
  );

  // 주이용점포 변경
  const setMainStore = useMutation(apiSetChangeMainStore, {
    onSuccess: () => {
      queryClient.invalidateQueries(['common', 'userInfo']);

      //스킵했을경우에는 alert 노출 없음
      if (selectedStore) {
        dispatch(
          actionOpenAlertPopup({
            onClose: () => {
              props.martSettingPopupState.onConfirm();
              dispatch(actionCloseAlertPopup());
            },
            children: <p>주이용 지점이 선택되었어요.</p>,
          }),
        );
      } else {
        props.martSettingPopupState.onConfirm();
      }
    },
  });

  /**
   * 지점 선택
   * @param _store
   */
  const fnClickStoreItem = (_store: TStore) => {
    setSelectedStore(_store);
  };

  /**
   * 주이용지점 설정하기 버튼 클릭
   * @returns
   */
  const fnClickSettingButton = () => {
    if (!selectedStore) {
      return;
    }

    if (setMainStore.isLoading) {
      return;
    }

    gaEvent({
      action: `[APP]BT Click_주이용점포 선택${selectedStore.str_nm}`,
      category: `[APP]BT Click_주이용점포 선택${selectedStore.str_nm}`,
      label: '',
    });

    setMainStore.mutate({ strCd: selectedStore.str_cd });
  };

  /**
   * 주이용지점 다음에 설정하기
   * @returns
   */
  const fnClickSkipButton = () => {
    if (setMainStore.isLoading) {
      return;
    }

    gaEvent({
      action: `[APP]BT Click_주이용점포 다음에 설정하기`,
      category: `[APP]BT Click_주이용점포 다음에 설정하기`,
      label: '',
    });

    setMainStore.mutate({ strCd: '361' });
  };

  /**
   * 팝업 닫기
   */
  const fnClose = () => {
    dispatch(actionCloseMartSettingPopup());
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    const defStr = data?.str_list.find(
      (str: TStore) => str.str_cd === userInfoState.def_str_cd,
    );
    setSelectedStore(defStr);
  }, [data, userInfoState]);

  useEffect(() => {
    gaPageView({ title: `[APP] ${'주이용점포'}`, pathname: '' });
  }, []);

  return (
    <>
      <Dim
        zIndex={198}
        isDisplay={props.martSettingPopupState.isOpen === E_POPUP_STATE.OPEN}
      />
      <CenteredPopupWrapper padding="0" zIndex={199}>
        {props.martSettingPopupState.isCloseButton && (
          <PopupHeaderOnlyClose onClose={fnClose} />
        )}
        <StyledMartSettingPopup>
          <div className="con">
            <div className="logo">
              <img
                src={`${IMAGE_ROOT_BOTTLE}/img_logo_login.png`}
                alt="BOTTLE BUNKER"
              />
            </div>

            <h5>주이용 지점 선택 안내</h5>

            <p>이용하실 지점을 선택해 주세요</p>

            {isSuccess && (
              <ul className="list">
                {data.str_list.map((store: TStore) => {
                  return (
                    <li
                      key={store.str_cd}
                      className={`${
                        selectedStore?.str_cd === store.str_cd && 'active'
                      }`}
                      onClick={() => fnClickStoreItem(store)}
                    >
                      <span>{store.str_nm}</span>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>

          <div className="btn_area">
            <Button
              text={'주이용 지점 설정'}
              buttonColor={selectedStore ? 'bottle_green' : 'gray'}
              variant={'contained'}
              onClick={fnClickSettingButton}
            />
          </div>
          {!userInfoState.def_str_cd && (
            <>
              <div className="btn_area">
                <Button
                  text={<>다음에 설정하기&nbsp;&nbsp;{`>`}</>}
                  buttonColor={'bottle_green'}
                  variant={'outlined'}
                  onClick={fnClickSkipButton}
                />
              </div>

              <p className="notice">
                &#8251; 주이용 지점을 다음에 설정하실 경우{' '}
                <strong>{`'잠실점'`}</strong>이 주이용 지점으로 선택됩니다.
              </p>
            </>
          )}
        </StyledMartSettingPopup>
      </CenteredPopupWrapper>
    </>
  );
}

export default MartSettingPopup;
