import React from 'react';
import { StyledLodingPopup } from './styles';
import Dim from '../Dim';
import { ICON_ROOT_BOTTLE } from 'src/utils/constants';

function LoadingPopup(): React.ReactElement {
  return (
    <>
      <StyledLodingPopup>
        <div className="loading">
          <img src={`${ICON_ROOT_BOTTLE}/loading.gif`} alt="로딩아이콘" />
        </div>
      </StyledLodingPopup>
      <Dim zIndex={998} isDisplay={true} />
    </>
  );
}

export default LoadingPopup;
