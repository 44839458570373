import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RootState } from 'src/store/reducers';
import useSelectorEqual from 'src/hooks/useSelectorEqual';

import { TTermsPopupProps } from 'src/redux/types/appTypes';
import { actionCloseTermsPouup } from 'src/redux/actions/appActions';
import { E_POPUP_STATE } from 'src/utils/enum';

import Dim from 'src/components/Dim';
import { SlideUpPopupWrapper } from 'src/components/Popup/components/SlideUpPopup';
import { PopupHeaderWithTitleAndClose } from 'src/components/Popup/components/PopupHeader';

import { StyledTerms } from './styles';
//import { gaPageView } from 'src/utils/ga';

type Props = {
  termsPopupState: TTermsPopupProps;
};

function Terms(props: Props): React.ReactElement {
  const dispatch = useDispatch();
  const { isOpen } = props.termsPopupState;

  const [termsPopupState] = useSelectorEqual((state: RootState) => [
    state.app.termsPopupState,
  ]);

  const handleClickClose = () => {
    dispatch(actionCloseTermsPouup());
  };

  useEffect(() => {
    if (!termsPopupState.agmtInfo.agmt_id) return;
    // gaPageView(
    //   location.pathname,
    //   `(${termsPopupState.agmtInfo.agmt_id}) ${termsPopupState.agmtInfo.agmt_nm}`,
    //   `약관 상세 팝업`,
    // );
  }, [termsPopupState.agmtInfo]);

  return (
    <>
      {termsPopupState.agmtInfo.agmt_id && (
        <>
          <Dim zIndex={209} isDisplay={isOpen} />
          <SlideUpPopupWrapper
            zIndex={210}
            isOpen={isOpen ? E_POPUP_STATE.OPEN : E_POPUP_STATE.CLOSE}
          >
            <>
              <PopupHeaderWithTitleAndClose
                title={termsPopupState.agmtInfo.agmt_nm}
                onClose={handleClickClose}
              />
              <StyledTerms>
                <div
                  dangerouslySetInnerHTML={{
                    __html: termsPopupState.agmtInfo.agmt_text,
                  }}
                ></div>
              </StyledTerms>
            </>
          </SlideUpPopupWrapper>
        </>
      )}
    </>
  );
}

export default Terms;
