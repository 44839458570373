import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import { font, thejamsilFont } from './font';

const GlobalStyles = createGlobalStyle`
/* css 리셋 */
  ${reset}
/* font */
  ${font}
  body {
    ${thejamsilFont('light')}
    
  }
  ::-webkit-scrollbar {
    display: none;
  }
  a{
      text-decoration: none;
      color:inherit;
  }
  button{
      border: none;
      background: none;
      padding: 0;
      outline: none;
      cursor: pointer;
      color: black;
  }
  select {
    margin: 0;
    border: none;
    padding: 0;
    font-size: 1rem;
    vertical-align: middle;
    color: #000;
    outline: 0;
    -webkit-appearance:none; 
    -moz-appearance:none; 
    appearance:none;
  }
  input,
  select,
  textarea {
    border-radius: 0;
    -webkit-border-radius: 0;
    background-color: #fff;
  }
  img,
  input {
    vertical-align: top;
    border: none;
    outline: none;
  }
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  font,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td {
    vertical-align: baseline;
    background: transparent;
    -webkit-text-size-adjust: none;
    list-style: none;
    line-height: 1;
    -webkit-tap-highlight-color: transparent;
  }


/* 스와이프 기본 css */
.swiper {
  margin-left: auto;
  margin-right: auto;
  list-style: none;
  padding: 0;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}
`;

export default GlobalStyles;
