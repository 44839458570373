import React, { useEffect } from 'react';
import CenteredPopupWrapper from './components/CenteredPopupWrapper';
import { Button } from '../Button';
import Dim from '../Dim';
import { E_POPUP_STATE } from 'src/utils/enum';
import { StyledBarcodePopup } from './styles';
import { useDispatch } from 'react-redux';
import { actionCloseBarcodePopup } from 'src/redux/actions/appActions';
import { bridgeBarcodeClose, bridgeBarcodeOpen } from 'src/utils/bridge';
import { useQuery } from '@tanstack/react-query';
import { apiGetAppCardInfo } from 'src/api/apiUser';
import JsBarcode from 'jsbarcode';

export default function BarcodePopup(props: {
  isOpen: E_POPUP_STATE;
}): React.ReactElement {
  const dispatch = useDispatch();

  const { isSuccess } = useQuery<{ membs_card_no: string }>(
    ['appCardInfo'],
    () => apiGetAppCardInfo(),
    {
      onSuccess: data => {
        const { membs_card_no } = data;
        if (membs_card_no) {
          const barcodeString =
            membs_card_no.substring(0, 4) +
            ' ' +
            membs_card_no.substring(4, 8) +
            ' ' +
            membs_card_no.substring(8, 12) +
            ' ' +
            membs_card_no.substring(12, 16);

          window.setTimeout(function () {
            JsBarcode('#barcode-img', membs_card_no, {
              background: '#ffffff',
              text: barcodeString,
              height: 70,
              textAlign: 'right',
              textMargin: 10,
              fontSize: 15,
            });
          }, 0);
        }
      },
    },
  );

  // 앱카드 닫기
  const fnCloseBarcodePopup = () => {
    dispatch(actionCloseBarcodePopup());
    // 화면 밝기 원복
    bridgeBarcodeClose();
  };

  useEffect(() => {
    // 화면 밝기 최대
    bridgeBarcodeOpen();
  }, []);

  return (
    <>
      <Dim zIndex={89} isDisplay={props.isOpen === E_POPUP_STATE.OPEN} />
      <CenteredPopupWrapper>
        <StyledBarcodePopup>
          <div className="header">
            <h3 className="tit">{'쿠폰 사용/L.POINT 적립'}</h3>
          </div>
          <p className="txt">매장에서 결제 시 보여주세요.</p>
          {isSuccess && (
            <div className="barcode">
              <svg id="barcode-img" />
            </div>
          )}
        </StyledBarcodePopup>
        <Button
          onClick={fnCloseBarcodePopup}
          text="확인"
          variant="contained"
          buttonColor="bottle_green"
        />
      </CenteredPopupWrapper>
    </>
  );
}
