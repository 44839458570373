import React from 'react';
import {
  StyledButton,
  StyledButtonFixedBottom,
  StyledButtonGroup,
} from './styles';
import { theme } from 'src/styles/theme';

const objColor = {
  bottle_green: theme.colors.bottle_green,
  red: theme.colors.red,
  black: theme.colors.black,
  gray: theme.colors.gray,
  light_green: '#377e79',
  white: theme.colors.white,
};

/**
 * @name 기본 버튼
 * @param text 버튼 이름
 * @param variant 버튼 스타일
 * @param buttonColor 버튼 색상
 */

export const Button = (
  props: {
    // onClick?: (e) => void;
    text: string | React.ReactNode;
    variant?: 'text' | 'contained' | 'outlined';
    buttonColor?:
      | 'red'
      | 'black'
      | 'gray'
      | 'bottle_green'
      | 'light_green'
      | 'white';
  } & React.ButtonHTMLAttributes<HTMLButtonElement>,
): React.ReactElement => {
  return (
    <StyledButton
      {...props}
      // onClick={props.onClick && props.onClick}
      variant={props.variant || 'contained'}
      buttonColor={objColor[props.buttonColor || 'bottle_green']}
    >
      {props.text}
    </StyledButton>
  );
};

/**
 * @name 기본 버튼 Wrapper (2개 이상일 경우 사용!!)
 * @prop count = 버튼갯수
 */

export const ButtonGroup = (
  props: {
    count: number;
    children: React.ReactNode;
  } & React.ButtonHTMLAttributes<HTMLDivElement>,
) => {
  return (
    <StyledButtonGroup {...props} count={props.count}>
      {props.children}
    </StyledButtonGroup>
  );
};

/**
 * 화면 하단 버튼 그룹 wrapper
 * @param props
 * @returns
 */
export const ButtonFixedBottom = (props: { children: React.ReactNode }) => {
  return <StyledButtonFixedBottom>{props.children}</StyledButtonFixedBottom>;
};
