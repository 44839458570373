import React from 'react';
import {
  StyledSlideUpFullScreenPopupWrapper,
  StyledSlideUpPopupWrapper,
} from '../styles';
import { E_POPUP_STATE } from 'src/utils/enum';

/**
 * @names 밑에서 위로 올라오는 팝업
 * @params isOpen 팝업 열고 닫기 여부
 */
export function SlideUpPopupWrapper(props: {
  isOpen: E_POPUP_STATE;
  children?: React.ReactNode;
  zIndex?: number;
}): React.ReactElement {
  return (
    <StyledSlideUpPopupWrapper
      zIndex={props.zIndex}
      className={props.isOpen === E_POPUP_STATE.OPEN ? 'open' : 'close'}
    >
      {props.children}
    </StyledSlideUpPopupWrapper>
  );
}

/**
 * @names 밑에서 위로 올라오는 풀스크린 팝업
 * @params isOpen 팝업 열고 닫기 여부
 */
export function SlideUpFullScreenPopupWrapper(props: {
  isOpen: E_POPUP_STATE;
  zIndex?: number;
  children?: React.ReactNode;
}): React.ReactElement {
  return (
    <StyledSlideUpFullScreenPopupWrapper
      zIndex={props.zIndex}
      className={props.isOpen === E_POPUP_STATE.OPEN ? 'open' : 'close'}
    >
      {props.children}
    </StyledSlideUpFullScreenPopupWrapper>
  );
}
