import { IMAGE_ROOT } from 'src/utils/constants';
import { css } from 'styled-components';

const FONT_PATH = `${IMAGE_ROOT}/fonts`;
const FONT_PATH_BOTTLE = `${process.env.REACT_APP_BASE_S3_IMAGE_BOTTLE}/fonts`;

// 폰트 정의
export const font = css`
  @font-face {
    font-family: 'TheJamsil-1Thin';
    src: url('${FONT_PATH}/TheJamsil-1-Thin.woff2') format('woff2'),
      url('${FONT_PATH}/TheJamsil-1-Thin.woff') format('woff');
  }
  @font-face {
    font-family: 'TheJamsil-2Light';
    src: url('${FONT_PATH}/TheJamsil-2-Light.woff2') format('woff2'),
      url('${FONT_PATH}/TheJamsil-2-Light.woff') format('woff');
  }
  @font-face {
    font-family: 'TheJamsil-3Regular';
    src: url('${FONT_PATH}/TheJamsil-3-Regular.woff2') format('woff2'),
      url('${FONT_PATH}/TheJamsil-3-Regular.woff') format('woff');
  }
  @font-face {
    font-family: 'TheJamsil-4Medium';
    src: url('${FONT_PATH}/TheJamsil-4-Medium.woff2') format('woff2'),
      url('${FONT_PATH}/TheJamsil-4-Medium.woff') format('woff');
  }
  @font-face {
    font-family: 'TheJamsil-5Bold';
    src: url('${FONT_PATH}/TheJamsil-5-Bold.woff2') format('woff2'),
      url('${FONT_PATH}/TheJamsil-5-Bold.woff') format('woff');
  }
  @font-face {
    font-family: 'TheJamsil-6ExtraBold';
    src: url('${FONT_PATH}/TheJamsil-6-ExtraBold.woff2') format('woff2'),
      url('${FONT_PATH}/TheJamsil-6-ExtraBold.woff') format('woff');
  }

  @font-face {
    font-family: 'Carmen Sans Thin';
    src: url('${FONT_PATH_BOTTLE}/CarmenSansThin.woff') format('woff');
  }
  @font-face {
    font-family: 'Carmen Sans UltraLight';
    src: url('${FONT_PATH_BOTTLE}/CarmenSansUltraLight.woff') format('woff');
  }
  @font-face {
    font-family: 'Carmen Sans Light';
    src: url('${FONT_PATH_BOTTLE}/CarmenSansLight.woff') format('woff');
  }
  @font-face {
    font-family: 'Carmen Sans SemiBold';
    src: url('${FONT_PATH_BOTTLE}/CarmenSansSemiBold.woff') format('woff');
  }
  @font-face {
    font-family: 'Carmen Sans Regular';
    src: url('${FONT_PATH_BOTTLE}/CarmenSansRegular.woff') format('woff');
  }
  @font-face {
    font-family: 'Carmen Sans Medium';
    src: url('${FONT_PATH_BOTTLE}/CarmenSansMedium.woff') format('woff');
  }
  @font-face {
    font-family: 'Carmen Sans Bold';
    src: url('${FONT_PATH_BOTTLE}/CarmenSansBold.woff') format('woff');
  }
  @font-face {
    font-family: 'Carmen Sans ExtraBold';
    src: url('${FONT_PATH_BOTTLE}/CarmenSansExtraBold.woff') format('woff');
  }
  @font-face {
    font-family: 'Carmen Sans Heavy';
    src: url('${FONT_PATH_BOTTLE}/CarmenSansHeavy.woff') format('woff');
  }

  @font-face {
    font-family: 'CentraNo2 Book';
    src: url('${FONT_PATH_BOTTLE}/CentraNo2-Book.woff') format('woff');
  }
`;

// 더 잠실체
export const thejamsilFont = (weight: string) => {
  switch (weight) {
    case 'thin':
      return css`
        font-family: 'TheJamsil-1Thin';
      `;
    case 'light':
      return css`
        font-family: 'TheJamsil-2Light';
      `;
    case 'regular':
      return css`
        font-family: 'TheJamsil-3Regular';
      `;
    case 'medium':
      return css`
        font-family: 'TheJamsil-4Medium';
      `;
    case 'bold':
      return css`
        font-family: 'TheJamsil-5Bold';
      `;
    case 'extrabold':
      return css`
        font-family: 'TheJamsil-6ExtraBold';
      `;
    default:
      return css``;
  }
};

// 더  카멘체
export const thecarmenFont = (weight: string) => {
  switch (weight) {
    case 'untralight':
      return css`
        font-family: 'Carmen Sans UltraLight';
      `;
    case 'thin':
      return css`
        font-family: 'Carmen Sans Thin';
      `;
    case 'light':
      return css`
        font-family: 'Carmen Sans Light';
      `;
    case 'regular':
      return css`
        font-family: 'Carmen Sans Regular';
      `;
    case 'medium':
      return css`
        font-family: 'Carmen Sans Medium';
      `;
    case 'semibold':
      return css`
        font-family: 'Carmen Sans SemiBold';
      `;
    case 'bold':
      return css`
        font-family: 'Carmen Sans Bold';
      `;
    case 'extrabold':
      return css`
        font-family: 'Carmen Sans CarmenSansExtraBold';
      `;
    case 'heavy':
      return css`
        font-family: 'Carmen Sans CarmenSansHeavy';
      `;

    default:
      return css``;
  }
};

// 더  카멘체
export const centraNoFont = (weight: string) => {
  switch (weight) {
    case 'book':
      return css`
        font-family: 'CentraNo2 Book';
      `;
    // case 'thin':
    //   return css`
    //     font-family: 'Carmen Sans Thin';
    //   `;
    // case 'light':
    //   return css`
    //     font-family: 'Carmen Sans Light';
    //   `;
    // case 'regular':
    //   return css`
    //     font-family: 'Carmen Sans Regular';
    //   `;
    // case 'medium':
    //   return css`
    //     font-family: 'Carmen Sans Medium';
    //   `;
    // case 'semibold':
    //   return css`
    //     font-family: 'Carmen Sans SemiBold';
    //   `;
    // case 'bold':
    //   return css`
    //     font-family: 'Carmen Sans Bold';
    //   `;
    // case 'extrabold':
    //   return css`
    //     font-family: 'Carmen Sans CarmenSansExtraBold';
    //   `;
    // case 'heavy':
    //   return css`
    //     font-family: 'Carmen Sans CarmenSansHeavy';
    //   `;

    default:
      return css``;
  }
};
