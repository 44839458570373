import React from 'react';
import { TConfirmPopupProps } from 'src/redux/types/appTypes';

import { StyledConfirmPopup, StyledConfirmPopupWrapper } from './styles';
import CenteredPopupWrapper from './components/CenteredPopupWrapper';
import { ConfirmPopupBanner } from './components/ConfirmPopupBanner';
import { Button, ButtonGroup } from '../Button';
import Dim from '../Dim';

function ConfirmPopup(props: {
  confirmPopupState: TConfirmPopupProps;
}): React.ReactElement {
  const {
    onConfirm,
    onClose,
    children,
    bannerChildren,
    cancelMessage,
    confirmMessage,
  } = props.confirmPopupState;

  const isBanner = bannerChildren ? true : false; // 배너 여부
  return (
    <>
      <Dim zIndex={300} isDisplay={true} />
      <CenteredPopupWrapper isBanner={isBanner}>
        {bannerChildren && (
          <ConfirmPopupBanner bannerChildren={bannerChildren} />
        )}
        <StyledConfirmPopupWrapper isBanner={isBanner}>
          <StyledConfirmPopup>
            <>{children}</>
          </StyledConfirmPopup>
          <ButtonGroup count={2}>
            {cancelMessage && (
              <Button
                onClick={onClose}
                text={cancelMessage}
                variant="contained"
                buttonColor="gray"
              />
            )}
            {confirmMessage && (
              <Button
                onClick={onConfirm}
                text={confirmMessage}
                variant="contained"
                buttonColor="bottle_green"
              />
            )}
          </ButtonGroup>
        </StyledConfirmPopupWrapper>
      </CenteredPopupWrapper>
    </>
  );
}

export default ConfirmPopup;
