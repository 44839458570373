/**
 * @name API 헤더 접근 서비스 ID
 * @param USER 회원&공통
 * @param COUPON 쿠폰
 * @param EVENT 이벤트
 * @param PUSH 푸쉬
 * @param PRODUCT 상품
 * @param BOTTLE 보틀벙커
 */
export const E_API_SERVICEID = {
  USER: 'USER',
  COUPON: 'COUPON',
  EVENT: 'EVENT',
  PUSH: 'PUSH',
  PRODUCT: 'PRODUCT',
  //'BOTTLE' = 'BOTTLE',
  BOTTLE:
    process.env.REACT_APP_TYPE === 'PROD' ||
    process.env.REACT_APP_TYPE === 'STAGE'
      ? 'BOTTLEBUNKER'
      : 'TEST_BOTTLE',
} as const;

/**
 * @name 이벤트목록 탭
 * @param ING 진행중
 * @param END 종료
 */
export enum E_EVENT_TAB {
  'ING' = 'ing',
  'END' = 'end',
}

/**
 * @name 쿠폰목록 탭
 * @param ALL 진행중
 * @param PRICE 종료
 * @param EXCHANGE  진행중
 * @param PRODUCT 종료
 */
export enum E_COUPON_TAB {
  'ALL' = 'all',
  'PRICE' = 'price',
  'EXCHANGE' = 'exchange',
  'PRODUCT' = 'product',
}

/**
 * @name 로그인 결과 상태 값
 * @param NONE 자동로그인 미설정
 * @param SUCCESS 로그인 성공
 * @param FAIL 로그인 실패
 * @param INIT 로그인 처리 전 or 로그아웃
 */
export enum E_INTRO_LOGIN_STATE {
  'NONE' = 'NONE',
  'SUCCESS' = 'SUCCESS',
  'FAIL' = 'FAIL',
  'INIT' = '',
}

/**
 * @name 팝업 열기/닫기 상태값
 * @param NONE 없음
 * @param OPEN 열기
 * @param CLOSE 닫기
 */
export enum E_POPUP_STATE {
  'OPEN' = 'OPEN',
  'CLOSE' = 'CLOSE',
  'NONE' = '',
}

/**
 * @name 회원약관 구분 값
 * @param MEMBER 롯데마트GO 회원약관
 * @param PRIVACY 롯데마트GO 개인정보 수집이용 동의
 * @param MARKETING 마케팅 목적 정보 이용 동의
 * @param PUSH 광고성 정보(PUSH) 수신 동의
 * @param CI 개인정보(CI) 수집 및 이용 동의
 * @param RECEIPT 스마트영수증만 받기 동의
 * @param CRM CRM 약관
 * @param LPOINT_PRIVACY 엘포인트 개인정보 수집이용동의
 * @param LPOINT_PROVIDE 엘포인트 제3자 제공동의
 */
export enum E_TERMS_AGMTTYPE {
  'MEMBER' = '1',
  'PRIVACY' = '2',
  'PRIVACY2' = '19',
  'MARKETING' = '3',
  'PUSH' = '4',
  'CI' = '5',
  'RECEIPT' = '6',
  'CRM' = '7',
  'LPOINT_PRIVACY' = '8',
  'LPOINT_PROVIDE' = '9',
}

/**
 * @name 보틀벙커 회원약관 회원약관 구분 값
 * @param MEMBER 보틀벙커 회원약관
 * @param PRIVACY 롯데마트GO 개인정보 수집이용 동의
 * @param MARKETING 마케팅 목적 정보 이용 동의
 * @param PUSH 광고성 정보(PUSH) 수신 동의
 */
export enum E_TERMS_AGMTTYPE_BOTTLE {
  'MEMBER' = '1',
  'PRIVACY' = '2',
  'MARKETING' = '3',
  'PUSH' = '4',
}

/**  링크방식 구분
 * @name API
 * @param APPLIST 앱 내 링크
 * @param URL 외부 링크
 * @param USELESS 링크없음
 */
export enum E_LINK_KIND {
  'APPLIST' = 'APPLIST',
  'URL' = 'URL',
  'USELESS' = 'USELESS',
}

/**
 * @name 시스템팝업 구분 값
 * @description 1:시스템점검 2:필수업데이트
 */
export enum E_SYSTEM_POPUP_TYPE {
  'INSPECTION' = '1',
  'UPDATE' = '2',
}

/**
 * @name 쿠폰 타입
 */
export enum E_COUPON_TYPE {
  'PRODUCT' = '1',
  'PRICE' = '2',
  'EXCHANGE' = '3',
}

/**
 * @name 등급 타입
 */
export enum GRADE_CODE {
  'FAMILY' = '1',
  'SILVER' = '2',
  'GOLD' = '3',
  'DIAMOND' = '4',
}

/**
 * @name 등급 명
 */
export enum GRADE_NAME {
  'FAMILY' = 'Family',
  'SILVER' = 'Silver',
  'GOLD' = 'Gold',
  'DIAMOND' = 'Diamond',
}

/**
 * @name 와인/양주 탭
 * @param ALL 전체
 * @param WINE 와인
 * @param LIQUOR 양주
 */
export enum E_WINELIQUOR_TAB {
  'ALL' = 'all',
  'WINE' = 'wine',
  'LIQUOR' = 'liquor',
}

/**
 * @name 상품상세 (와인)탭
 */
export enum E_PRODUCT_WINE_TAB {
  'STYLE' = 'style',
  'INFO' = 'info',
  'WINERY' = 'winery',
}

/**
 * @name 상품상세 (양주)탭
 */
export enum E_PRODUCT_LIQUOR_TAB {
  'INFO' = 'info',
  'STYLE' = 'style',
  'DISTILLERY' = 'distillery',
}

/**
 * @name 검색 필터 탭
 */
export enum E_SEARCH_FILTER_TAB {
  'TYPE' = 'type',
  'NATION' = 'nation',
  'KIND' = 'kind',
  'FIVESENSE' = 'fivesense',
  'STYLE' = 'style',
  'PRICE' = 'price',
}

/**
 * @name 예약알림 신청 탭
 */
export enum E_RESERVATION_ALRAM_TAB {
  'RESTOCK' = 'restock',
  'RESERVATION' = 'reservation',
}

/**
 * @name 상품 예약 상태 코드
 */
export enum E_PRODUCT_RESERVATION_STATE {
  'PICK_UP_FORBID' = 'PICK_UP_FORBID', //APP 예약불가 (예약알림신청불가)
  'PICK_UP_FORBID_PUSH_Y' = 'PICK_UP_FORBID_PUSH_Y', //APP예약불가|APP예약알림신청
  'PICK_UP_FORBID_PUSH_N' = 'PICK_UP_FORBID_PUSH_N', //APP예약불가|APP예약알림취소(알림신청완료상태)
  'PICK_UP_OPEN_BEFORE' = 'PICK_UP_OPEN_BEFORE', //예약시작일 픽업예약오픈이전
  'SOLD_OUT_RESTOKE_Y' = 'SOLD_OUT_RESTOKE_Y', //품절|재입고 알림 신청
  'SOLD_OUT_RESTOKE_N' = 'SOLD_OUT_RESTOKE_N', //품절|재입고 알림 신청취소(알림신청완료상태)
  'PICK_UP' = 'PICK_UP', //픽업예약가능 - 상품상세에서는 사용하지않음
  'PICK_UP_TP_1' = 'PICK_UP_TP_1', //픽업예약 (4병이상 가능) - 상품상세에서만 사용
  'PICK_UP_TP_2' = 'PICK_UP_TP_2', //픽업예약 (1~3병 가능) (마감임박) -상품상세에서만 사용
  'PICK_UP_COMPL' = 'PICK_UP_COMPL', // 픽업예약완료(예약이력존재)
}
