enum ActionTypes {
  OPEN_CONFIRM_POPUP = 'app/OPEN_CONFIRM_POPUP',
  CLOSE_CONFIRM_POPUP = 'app/CLOSE_CONFIRM_POPUP',
  OPEN_ALERT_POPUP = 'app/OPEN_ALERT_POPUP',
  CLOSE_ALERT_POPUP = 'app/CLOSE_ALERT_POPUP',
  OPEN_TERMS_POPUP = 'app/OPEN_TERMS_POPUP',
  CLOSE_TERMS_POPUP = 'app/CLOSE_TERMS_POPUP',
  OPEN_LOADING_POPUP = 'app/OPEN_LOADING_POPUP',
  CLOSE_LOADING_POPUP = 'app/CLOSE_LOADING_POPUP',
  APP_DEVICE_INFO = 'app/APP_DEVICE_INFO',
  OPEN_MART_SETTING_POPUP = 'app/OPEN_MART_SETTING_POPUP',
  CLOSE_MART_SETTING_POPUP = 'app/CLOSE_MART_SETTING_POPUP',
  OPEN_INSPECTION_APPCARD_POPUP = 'app/OPEN_INSPECTION_APPCARD_POPUP',
  CLOSE_INSPECTION_APPCARD_POPUP = 'app/CLOSE_INSPECTION_APPCARD_POPUP',
  OPEN_BARCODE_POPUP = 'app/OPEN_BARCODE_POPUP',
  CLOSE_BARCODE_POPUP = 'app/CLOSE_BARCODE_POPUP',
  OPEN_STOREINFO_POPUP = 'app/OPEN_STOREINFO_POPUP',
  CLOSE_STOREINFO_POPUP = 'app/CLOSE_STOREINFO_POPUP',
}

export default ActionTypes;
