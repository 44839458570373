import { rem } from 'src/styles/function';
import { ICON_PATH, ICON_ROOT_BOTTLE } from 'src/utils/constants';
import styled, { css } from 'styled-components';

/**
 * @name 아이콘 스타일 컴포넌트
 */
export const StyledIcon = styled.span`
  background: transparent
    url('https://data.ac-illust.com/data/thumbnails/3a/3a6979f7f1cd310149a3f311fee442aa_t.jpeg')
    no-repeat 50% 50%;
  background-size: 100% 100%;
  display: block;
  cursor: pointer;

  &.icon_clear {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_clear.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.icon_login_error {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_login_error.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.icon_page_loading {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/loading_fnnut.gif') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_close {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_close.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_close_white {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_close_white.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_share {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/ico_share.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_share_black {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/ico_share_black.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_dropdown {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_dropdown.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_dropdown_round_down {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/ico_dropdown_round_down.png') no-repeat
      0 0;
    background-size: 100% auto;
  }

  &.ico_search {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_search.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_back {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_prev.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_back_white {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_prev_white.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_qrcode {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/ico_qrcode.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_question {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_question.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_question_white {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/ico_question_white.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.icon_arrow_bg {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_arrow_bg.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_next {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_next.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_next_bold {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_next_bold.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.icon_filter {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_filter.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_reset {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_reset.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.icon_barcode {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/ico_barcode.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.icon_barcode_w {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/ico_barcode_w.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.icon_alram_subheader {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/ico_alram_subheader.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.icon_alram_subheader_w {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/ico_alram_subheader_w.png') no-repeat 0
      0;
    background-size: 100% auto;
  }

  &.ico_alram_restock {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/ico_alram_restock.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.icon_favorites_on {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_wish_on.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_favorites_off {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_wish.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.ico_shoppingbag {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/ico_shoppingbag.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_update {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_rotate.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.ico_starpoint {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_new_star.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.ico_starpoint_active {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_new_star_active.png') no-repeat 0
      0;
    background-size: 100% auto;
  }

  &.ico_starpoint_green {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_star_type03.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.ico_starpoint_green_active {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_star_type03_active.png') no-repeat
      0 0;
    background-size: 100% auto;
  }

  &.icon_marker {
    width: 100%;
    height: 100%;
    &.white {
      background: url('${ICON_ROOT_BOTTLE}/icon_mark_white.png') no-repeat 0 0;
      background-size: 100% auto;
    }
    &.purple {
      background: url('${ICON_ROOT_BOTTLE}/icon_mark_purple.png') no-repeat 0 0;
      background-size: 100% auto;
    }
    &.red {
      background: url('${ICON_ROOT_BOTTLE}/icon_mark_red.png') no-repeat 0 0;
      background-size: 100% auto;
    }
  }

  &.icon_call {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_call.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.icon_copy {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_copy_red.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_arrow_down {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_arrow_down.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_arrow_up {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_arrow_up.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_arrow_bar_right {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/ico_arrow_bar_right.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_triangle {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_triangle.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_qrcode {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/ico_qrcode.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.icon_next {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_next.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.icon_pause {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_pause.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.icon_play {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_play.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.icon_cart {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_cart.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.icon_quotes {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_quotes.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.icon_order {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_order.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.icon_advertise {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_advertise.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.icon_clock {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_clock.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_write {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/ico_write.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_grade_notice {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/ico_grade_notice.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.icon_footer_home {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_footer_home_off.png') no-repeat 0
      0;
    background-size: 100% auto;

    &.active {
      background: url('${ICON_ROOT_BOTTLE}/icon_footer_home_on.png') no-repeat 0
        0;
      background-size: 100% auto;
    }
  }

  &.icon_footer_foodpairing {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_footer_foodpairing_off.png')
      no-repeat 0 0;
    background-size: 100% auto;

    &.active {
      background: url('${ICON_ROOT_BOTTLE}/icon_footer_foodpairing_on.png')
        no-repeat 0 0;
      background-size: 100% auto;
    }
  }

  &.icon_footer_search {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_footer_search_off.png') no-repeat
      0 0;
    background-size: 100% auto;

    &.active {
      background: url('${ICON_ROOT_BOTTLE}/icon_footer_search_on.png') no-repeat
        0 0;
      background-size: 100% auto;
    }
  }

  &.icon_footer_mybunker {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_footer_mybunker_off.png')
      no-repeat 0 0;
    background-size: 100% auto;

    &.active {
      background: url('${ICON_ROOT_BOTTLE}/icon_footer_mybunker_on.png')
        no-repeat 0 0;
      background-size: 100% auto;
    }
  }

  &.icon_footer_mypage {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/icon_footer_mypage_off.png') no-repeat
      0 0;
    background-size: 100% auto;

    &.active {
      background: url('${ICON_ROOT_BOTTLE}/icon_footer_mypage_on.png') no-repeat
        0 0;
      background-size: 100% auto;
    }
  }

  &.ico_wine {
    width: 100%;
    height: 100%;
    &.red {
      background: url('${ICON_ROOT_BOTTLE}/ico_wine_red.png') no-repeat 0 0;
      background-size: 100% auto;
    }
    &.sparkling {
      background: url('${ICON_ROOT_BOTTLE}/ico_wine_sparkling.png') no-repeat 0
        0;
      background-size: 100% auto;
    }
    &.rose {
      background: url('${ICON_ROOT_BOTTLE}/ico_wine_rose.png') no-repeat 0 0;
      background-size: 100% auto;
    }
    &.white {
      background: url('${ICON_ROOT_BOTTLE}/ico_wine_white.png') no-repeat 0 0;
      background-size: 100% auto;
    }
    &.none {
      background: url('${ICON_ROOT_BOTTLE}/ico_wine_non.png') no-repeat 0 0;
      background-size: 100% auto;
    }
    &.natural {
      background: url('${ICON_ROOT_BOTTLE}/ico_wine_natural.png') no-repeat 0 0;
      background-size: 100% auto;
    }
    &.fortified {
      background: url('${ICON_ROOT_BOTTLE}/ico_wine_fortified.png') no-repeat 0
        0;
      background-size: 100% auto;
    }
  }

  &.ico_bookmark {
    width: 100%;
    height: 100%;
    background: url('${ICON_ROOT_BOTTLE}/ico_bookmark_off.png') no-repeat 0 0;
    background-size: 100% auto;

    &.active {
      background: url('${ICON_ROOT_BOTTLE}/ico_bookmark_on.png') no-repeat 0 0;
      background-size: 100% auto;
    }
  }

  &.ico_liquor {
    width: 100%;
    height: 100%;
    &.singlemalt_whisky {
      background: url('${ICON_ROOT_BOTTLE}/ico_singlemalt_whisky.png') no-repeat
        0 0;
      background-size: 100% auto;
    }
    &.blended_whisky {
      background: url('${ICON_ROOT_BOTTLE}/ico_blended_whisky.png') no-repeat 0
        0;
      background-size: 100% auto;
    }
    &.bourbon_whisky {
      background: url('${ICON_ROOT_BOTTLE}/ico_bourbon_whisky.png') no-repeat 0
        0;
      background-size: 100% auto;
    }
    &.japan_whisky {
      background: url('${ICON_ROOT_BOTTLE}/ico_japan_whisky.png') no-repeat 0 0;
      background-size: 100% auto;
    }
    &.brandy {
      background: url('${ICON_ROOT_BOTTLE}/ico_brandy.png') no-repeat 0 0;
      background-size: 100% auto;
    }
    &.rum {
      background: url('${ICON_ROOT_BOTTLE}/ico_rum.png') no-repeat 0 0;
      background-size: 100% auto;
    }
    &.gin {
      background: url('${ICON_ROOT_BOTTLE}/ico_gin.png') no-repeat 0 0;
      background-size: 100% auto;
    }
    &.vodka {
      background: url('${ICON_ROOT_BOTTLE}/ico_vodka.png') no-repeat 0 0;
      background-size: 100% auto;
    }
    &.tequila {
      background: url('${ICON_ROOT_BOTTLE}/ico_tequila.png') no-repeat 0 0;
      background-size: 100% auto;
    }
    &.sake {
      background: url('${ICON_ROOT_BOTTLE}/ico_sake.png') no-repeat 0 0;
      background-size: 100% auto;
    }
    &.baijiu {
      background: url('${ICON_ROOT_BOTTLE}/ico_baijiu.png') no-repeat 0 0;
      background-size: 100% auto;
    }
    &.liqueur {
      background: url('${ICON_ROOT_BOTTLE}/ico_liqueur.png') no-repeat 0 0;
      background-size: 100% auto;
    }
    &.gift {
      background: url('${ICON_ROOT_BOTTLE}/ico_gift.png') no-repeat 0 0;
      background-size: 100% auto;
    }
    &.flatprice {
      background: url('${ICON_ROOT_BOTTLE}/ico_flatprice.png') no-repeat 0 0;
      background-size: 100% auto;
    }
    &.miniature {
      background: url('${ICON_ROOT_BOTTLE}/ico_miniature.png') no-repeat 0 0;
      background-size: 100% auto;
    }
  }
`;

/**
 * @name 이벤트 상태 뱃지
 */
export const StyledIconEventBadge = styled.i<{
  eventStatusCode: 'ING' | 'BEFORE' | 'END';
}>`
  display: block;
  width: 4rem;
  height: 1.5rem;
  ${props => {
    if (props.eventStatusCode === 'BEFORE') {
      return css`
        background-image: url('${ICON_ROOT_BOTTLE}/ico_event_pre.png');
        background-size: 4rem 1.5rem;
      `;
    } else if (props.eventStatusCode === 'ING') {
      return css`
        background-image: url('${ICON_ROOT_BOTTLE}/ico_event_ing.png');
        background-size: 4rem 1.5rem;
      `;
    } else {
      return css``;
    }
  }}
`;

/**
 * @name 테이스팅클래스 상태 뱃지
 */
export const StyledIconTastingClassBadge = styled.span<{
  eventStatusCode: 'CLASS_SOON' | 'CLASS_ING' | 'CLASS_END';
}>`
  display: block;
  width: 100%;
  height: 100%;
  ${props => {
    if (props.eventStatusCode === 'CLASS_SOON') {
      return css`
        background: url('${ICON_ROOT_BOTTLE}/ico_class_pre.png') no-repeat 0 0;
        background-size: 100% auto;
      `;
    } else if (props.eventStatusCode === 'CLASS_ING') {
      return css`
        background: url('${ICON_ROOT_BOTTLE}/ico_class_ing.png') no-repeat 0 0;
        background-size: 85% auto;
      `;
    } else if (props.eventStatusCode === 'CLASS_END') {
      return css`
        background: url('${ICON_ROOT_BOTTLE}/ico_class_end.png') no-repeat 0 0;
        background-size: 100% auto;
      `;
    } else {
      return css``;
    }
  }}
`;

/**
 * @name 강조하기 빨간점
 */
export const StyledIconRedDot = styled.span`
  display: inline-block;
  width: ${rem(6)};
  width: ${rem(6)};
  height: ${rem(6)};
  border-radius: ${rem(6)};
  background: ${({ theme }) => theme.colors.red};
  vertical-align: top;
  position: relative;
  top: ${rem(4)};
  left: ${rem(6)};
`;
