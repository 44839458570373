import React, { useRef, useEffect } from 'react';

import { IMAGE_ROOT_BOTTLE } from 'src/utils/constants';
import { StyledInspection } from './styles';
import { bridgeHideSplash, bridgeQuit } from 'src/utils/bridge';
import { Button } from 'src/components/Button';

/**
 * @name 점검안내 페이지
 * @returns
 */
function SystemInspection(): React.ReactElement {
  const imgRef = useRef<HTMLImageElement>(null);

  const fnErrorImage = () => {
    //배경이미지 없거나 에러인 경우 기본 이미지
    imgRef.current?.setAttribute(
      'src',
      `${IMAGE_ROOT_BOTTLE}/system/system_inspection.png`,
    );
  };

  const fnQuit = () => {
    bridgeQuit();
  };

  useEffect(() => {
    bridgeHideSplash();
  }, []);

  return (
    <>
      <StyledInspection>
        <img
          src={`${IMAGE_ROOT_BOTTLE}/system/system_inspection.png`}
          alt="시스템 점검안내"
          onError={fnErrorImage}
          ref={imgRef}
        />
        <div className="btn">
          <Button
            text="앱종료"
            variant="contained"
            buttonColor="bottle_green"
            onClick={fnQuit}
          />
        </div>
      </StyledInspection>
    </>
  );
}

export default SystemInspection;
