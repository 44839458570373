import { E_POPUP_STATE } from 'src/utils/enum';
import { AppAction } from '../actions/appActions';
import ActionTypes from '../constants/appConstants';
import { TAppState } from '../types/appTypes';

export const initialState = {
  confirmPopupState: {
    isOpen: false,
    onClose: function (): void {
      return;
    },
    onConfirm: function (): void {
      return;
    },
  },
  alertPopupState: {
    isOpen: false,
    onClose: function (): void {
      return;
    },
  },
  termsPopupState: {
    isOpen: false,
    agmtInfo: {
      agmt_desc: '',
      agmt_id: '',
      agmt_dvs: '',
      agmt_dvs_nm: '',
      agmt_type: '',
      agmt_type_nm: '',
      must_yn: '',
      agmt_nm: '',
      agmt_ver: '',
      agmt_s3_uri: '',
      agmt_text: '',
      recv_dvs: '',
      recv_dvs_nm: '',
    },
  },

  isOpenLoadingPopup: E_POPUP_STATE.NONE,
  martSettingPopupState: {
    isOpen: E_POPUP_STATE.NONE,
    isCloseButton: false,
    onClose: function (): void {
      return;
    },
    onConfirm: function (): void {
      return;
    },
  },
  isOpenInspectionAppcardPopup: E_POPUP_STATE.NONE,
  deviceInfo: {
    device_id: '',
    device_os: '',
    model_nm: '',
    app_ver: '',
    wdata: '',
    ukey: '',
  },
  isOpenBarcodePopupState: E_POPUP_STATE.NONE,
  storeInfoPopupState: {
    isOpen: E_POPUP_STATE.NONE,
    strCd: '',
  },
};

function AppReducer(
  state: TAppState = initialState,
  action: AppAction,
): TAppState {
  switch (action.type) {
    case ActionTypes.OPEN_CONFIRM_POPUP:
      return {
        ...state,
        confirmPopupState: {
          isOpen: true,
          confirmMessage: action.payload.confirmPopupState.confirmMessage,
          onConfirm: action.payload.confirmPopupState.onConfirm,
          onClose: action.payload.confirmPopupState.onClose,
          cancelMessage: action.payload.confirmPopupState.cancelMessage,
          children: action.payload.confirmPopupState.children,
          bannerChildren: action.payload.confirmPopupState.bannerChildren,
        },
      };
    case ActionTypes.CLOSE_CONFIRM_POPUP:
      return {
        ...state,
        confirmPopupState: {
          isOpen: false,
          onConfirm: function (): void {
            return;
          },
          onClose: function (): void {
            return;
          },
        },
      };

    case ActionTypes.OPEN_ALERT_POPUP:
      return {
        ...state,
        alertPopupState: {
          isOpen: true,
          onClose: action.payload.alertPopupState.onClose,
          children: action.payload.alertPopupState.children,
        },
      };
    case ActionTypes.CLOSE_ALERT_POPUP:
      return {
        ...state,
        alertPopupState: {
          isOpen: false,
          onClose: function (): void {
            return;
          },
        },
      };
    case ActionTypes.OPEN_TERMS_POPUP:
      return {
        ...state,
        termsPopupState: {
          isOpen: true,
          agmtInfo: action.payload.termsPopupState.agmtInfo,
        },
      };
    case ActionTypes.CLOSE_TERMS_POPUP:
      return {
        ...state,
        termsPopupState: {
          isOpen: false,
          agmtInfo: {
            agmt_desc: '',
            agmt_id: '',
            agmt_dvs: '',
            agmt_dvs_nm: '',
            agmt_type: '',
            agmt_type_nm: '',
            must_yn: '',
            agmt_nm: '',
            agmt_ver: '',
            agmt_s3_uri: '',
            agmt_text: '',
            recv_dvs: '',
            recv_dvs_nm: '',
          },
        },
      };

    case ActionTypes.OPEN_LOADING_POPUP:
      return {
        ...state,
        isOpenLoadingPopup: E_POPUP_STATE.OPEN,
      };
    case ActionTypes.CLOSE_LOADING_POPUP:
      return {
        ...state,
        isOpenLoadingPopup: E_POPUP_STATE.CLOSE,
      };

    case ActionTypes.APP_DEVICE_INFO:
      return {
        ...state,
        deviceInfo: action.payload.deviceInfo,
      };

    case ActionTypes.OPEN_MART_SETTING_POPUP:
      return {
        ...state,
        martSettingPopupState: {
          isOpen: E_POPUP_STATE.OPEN,
          isCloseButton: action.payload.martSettingPopupState.isCloseButton,
          onConfirm: action.payload.martSettingPopupState.onConfirm,
          onClose: action.payload.martSettingPopupState.onClose,
        },
      };

    case ActionTypes.CLOSE_MART_SETTING_POPUP:
      return {
        ...state,
        martSettingPopupState: {
          isOpen: E_POPUP_STATE.CLOSE,
          isCloseButton: false,
          onConfirm: function (): void {
            return;
          },
          onClose: function (): void {
            return;
          },
        },
      };
    case ActionTypes.OPEN_INSPECTION_APPCARD_POPUP:
      return {
        ...state,
        isOpenInspectionAppcardPopup: E_POPUP_STATE.OPEN,
      };
    case ActionTypes.CLOSE_INSPECTION_APPCARD_POPUP:
      return {
        ...state,
        isOpenInspectionAppcardPopup: E_POPUP_STATE.CLOSE,
      };
    case ActionTypes.OPEN_BARCODE_POPUP:
      return {
        ...state,
        isOpenBarcodePopupState: E_POPUP_STATE.OPEN,
      };
    case ActionTypes.CLOSE_BARCODE_POPUP:
      return {
        ...state,
        isOpenBarcodePopupState: E_POPUP_STATE.CLOSE,
      };

    case ActionTypes.OPEN_STOREINFO_POPUP:
      return {
        ...state,
        storeInfoPopupState: {
          isOpen: E_POPUP_STATE.OPEN,
          strCd: action.payload.strCd || '',
        },
      };

    case ActionTypes.CLOSE_STOREINFO_POPUP:
      return {
        ...state,
        storeInfoPopupState: {
          isOpen: E_POPUP_STATE.CLOSE,
          strCd: '',
        },
      };
    default:
      return state;
  }
}

export default AppReducer;
