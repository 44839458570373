import React, { useEffect, useMemo, useRef, useState } from 'react';
import { StyledStoreInfoPopup } from './styles';
import Dim from '../Dim';
import CenteredPopupWrapper from './components/CenteredPopupWrapper';
import { Button } from '../Button';
import { TStoreInfoPopupProps } from 'src/redux/types/appTypes';
import { E_INTRO_LOGIN_STATE, E_POPUP_STATE } from 'src/utils/enum';
import { useDispatch } from 'react-redux';
import { actionCloseStoreInfoPopup } from 'src/redux/actions/appActions';
import { useQueries } from '@tanstack/react-query';
import { apiGetStoreInfo, apiGetStoreList } from 'src/api/apiUser';
import moment from 'moment';
import { TStore } from 'src/types/types';
import { IcoCall, IcoCopy, IcoDropDown, IcoMarker } from '../Icon';
import { isAndWebView, isIOSWebView } from 'src/utils/utility';
import { bridgeOpenMapWebview } from 'src/utils/bridge';
import useSelectorEqual from 'src/hooks/useSelectorEqual';
import { RootState } from 'src/store/reducers';
import { gaEvent, gaPageView } from 'src/utils/ga';
import { useLocation } from 'react-router-dom';

export default function StoreInfoPopup(props: {
  storeInfoPopupState: TStoreInfoPopupProps;
}): React.ReactElement {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [loginStatus, userInfoState] = useSelectorEqual((state: RootState) => [
    state.login.loginStatus,
    state.user.userInfoState,
  ]);

  const [selectedStore, setSelectedStore] = useState<TStore>(); //선택한 매장 코드

  const queries = useQueries({
    queries: [
      {
        queryKey: ['storeInfoPopup', 'storeList'],
        queryFn: apiGetStoreList,
        onSuccess: (data: { str_list: TStore[] }) => {
          let _strCd = '';

          if (loginStatus === E_INTRO_LOGIN_STATE.SUCCESS) {
            // 로그인 상태이고
            if (props.storeInfoPopupState.strCd) {
              // 선택한 점포가 있으면 선택한 점포로
              _strCd = props.storeInfoPopupState.strCd;
            } else {
              // 선택한 점포가 없으면 주이용점포로
              _strCd = userInfoState.def_str_cd;
            }
          } else {
            //로그인 상태가 아니면 서울역점
            _strCd = '201';
          }

          const initStore = data.str_list.find(
            store => store.str_cd === _strCd,
          );
          setSelectedStore(initStore);
        },
      },
      {
        queryKey: ['storeInfoPopup', 'storeInfo', selectedStore],
        queryFn: () => apiGetStoreInfo({ str_cd: selectedStore?.str_cd }),
        enabled: !!selectedStore,
      },
    ],
  });

  const storeList: {
    str_list: TStore[];
  } = useMemo(() => {
    return queries[0].data;
  }, [queries]);

  const storeInfo: TStore = useMemo(() => {
    return queries[1].data;
  }, [queries]);

  /**
   * 매장 선택
   * @param clickedStore
   */
  const fnChangeFilter = e => {
    const _store = storeList.str_list.find(
      str => str.str_cd === e.target.value,
    );

    if (!_store) {
      return;
    }
    gaEvent({
      action: `[APP]List Click_지점안내 팝업_${_store.str_nm}`,
      category: `[APP]List Click_지점안내 팝업_${_store.str_nm}`,
      label: '',
    });

    setSelectedStore(_store);
  };

  /**
   * 팝업 닫기
   */
  const fnClose = () => {
    dispatch(actionCloseStoreInfoPopup());
  };

  /**
   * 주소복사
   */
  const fnClickCopyAddr = () => {
    const str_addr = storeInfo?.str_addr || '';

    if (str_addr) {
      try {
        gaEvent({
          action: `[APP]Link Click_지점안내 팝업_오시는길 주소복사_${storeInfo.str_nm}`,
          category: `[APP]Link Click_지점안내 팝업_오시는길 주소복사_${storeInfo.str_nm}`,
          label: '',
        });

        if (isIOSWebView) {
          webkit.messageHandlers.setClipBoard.postMessage({
            text: str_addr,
            message: '주소가 복사 되었습니다.',
          });
        } else if (isAndWebView) {
          window.binder?.setClipBoard(str_addr, '주소가 복사 되었습니다.');
        }
      } catch (e) {
        console.log('bridge error setClipBoard');
      }
    }
  };

  /**
   * 매장지도 보기 클릭
   */
  const fnShowMap = () => {
    if (!storeInfo) {
      return;
    }

    gaEvent({
      action: `[APP]Link Click_지점안내 팝업_매장지도보기_${storeInfo.str_nm}`,
      category: `[APP]Link Click_지점안내 팝업_매장지도보기_${storeInfo.str_nm}`,
      label: '',
    });

    if (process.env.REACT_APP_TYPE === 'LOCAL') {
      // 상품 위치 팝업의 header에 지점명 및 상품 위치 셋팅
      // 팝업창에서 데이터 조회하려면 API를 호출해야 하므로, 해당 호출을 하지 않기 위함
    } else {
      const url = `${window.location.origin}/product/findplace?str_cd=${storeInfo.str_cd}`;

      bridgeOpenMapWebview(storeInfo.str_nm, url);
    }
  };

  /**
   * 전화걸기
   * @param _storeInfo
   * @returns
   */
  const fnClickCall = (_storeInfo: TStore) => {
    if (!_storeInfo.str_tel_no) {
      return;
    }
    const telNumber = _storeInfo.str_tel_no.replace(/-/g, '');

    try {
      if (isIOSWebView) {
        webkit.messageHandlers.callPhone.postMessage({ number: telNumber });
      } else if (isAndWebView) {
        window.binder?.callPhone(telNumber);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    gaPageView({ title: `[APP] ${'지점안내팝업'}`, pathname: pathname });
  }, [pathname]);

  return (
    <>
      <Dim
        zIndex={300}
        isDisplay={props.storeInfoPopupState.isOpen === E_POPUP_STATE.OPEN}
      />
      <CenteredPopupWrapper>
        <StyledStoreInfoPopup>
          <div className="header" ref={componentRef}>
            <h5>지점안내</h5>
            {storeList && selectedStore && (
              <div className="filter">
                <select
                  onChange={e => fnChangeFilter(e)}
                  value={selectedStore.str_cd}
                >
                  {storeList.str_list.map(filter => {
                    return (
                      <option value={filter.str_cd} key={filter.str_cd}>
                        {filter.str_nm}
                      </option>
                    );
                  })}
                </select>
                <i className="ico">
                  <IcoDropDown />
                </i>
              </div>
            )}
          </div>
          {storeInfo && (
            <div className="con">
              <p className="subtitle">보틀벙커 {storeInfo.str_nm}</p>
              <p className="txt">
                {`전화 : ${storeInfo.str_tel_no}`}
                <button className="call" onClick={() => fnClickCall(storeInfo)}>
                  <i>
                    <IcoCall />
                  </i>
                </button>
              </p>
              <p className="txt">
                {`${storeInfo.bsns_conts} : ${storeInfo.bsns_start_time} -
                ${storeInfo.bsns_end_time}`}
              </p>
              <p className="txt">{storeInfo.hldy_conts}</p>
              <p className="txt">
                {storeInfo.hldy_list.map((hidy, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      {moment(hidy).format('MM월 DD일')}
                      {idx + 1 < storeInfo.hldy_list.length && `, `}
                    </React.Fragment>
                  );
                })}
              </p>
              <p className="subtitle">픽업데스크/컨시어지 서비스</p>
              <p className="txt">{storeInfo.pick_desk_info}</p>
              <p className="btn icon" onClick={fnShowMap}>
                <i>
                  <IcoMarker color="red"></IcoMarker>
                </i>
                매장 지도 보기
              </p>
              <p className="subtitle">오시는 길</p>
              <p className="txt">
                {storeInfo.str_addr}
                <br />
                {storeInfo.road_info}
              </p>
              <p className="btn icon" onClick={fnClickCopyAddr}>
                <i>
                  <IcoCopy />
                </i>
                주소복사
              </p>
            </div>
          )}
        </StyledStoreInfoPopup>
        <Button
          onClick={fnClose}
          text="확인"
          variant="contained"
          buttonColor="bottle_green"
        />
      </CenteredPopupWrapper>
    </>
  );
}
