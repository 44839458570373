import { rem } from 'src/styles/function';
import styled from 'styled-components';

/**
 * @name 점검안내 스타일 컴포넌트
 */
export const StyledInspection = styled.section`
  margin-top: ${rem(80)};

  img {
    width: 100%;
  }

  .btn {
    display: flex;
    justify-content: space-around;
    position: fixed;
    width: 100%;
    z-index: 10;
    bottom: 0;
  }
`;
