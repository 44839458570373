import { combineReducers } from 'redux';

import app from '../redux/reducers/appReducer';
import login from '../redux/reducers/loginReducer';
import user from '../redux/reducers/userReducer';

const rootReducer = combineReducers({
  app,
  login,
  user,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
