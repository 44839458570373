import React from 'react';
import {
  StyledIcon,
  StyledIconEventBadge,
  StyledIconRedDot,
  StyledIconTastingClassBadge,
} from './styles';
import { TBottleProduct } from 'src/types/types';

/***************** 마트GO 아이콘 ***************** */
/**
 * @name 삭제 아이콘
 */
export const IcoClear = (): React.ReactElement => {
  return <StyledIcon className="icon_clear"></StyledIcon>;
};

/**
 * @name 에러 아이콘
 */
export const IcoLoginError = (): React.ReactElement => {
  return <StyledIcon className="icon_login_error"></StyledIcon>;
};

/***************** 마트GO 아이콘 END***************** */

/**
 * @name 닫기 아이콘
 */
export const IcoClose = (): React.ReactElement => {
  return <StyledIcon className="ico_close"></StyledIcon>;
};

/**
 * @name 닫기 아이콘
 */
export const IcoCloseWhite = (): React.ReactElement => {
  return <StyledIcon className="ico_close_white"></StyledIcon>;
};

/**
 * @name 공유하기 아이콘
 */
export const IcoShare = (): React.ReactElement => {
  return <StyledIcon className="ico_share"></StyledIcon>;
};

/**
 * @name 공유하기 검정 아이콘
 */
export const IcoShareBlack = (): React.ReactElement => {
  return <StyledIcon className="ico_share_black"></StyledIcon>;
};

/**
 * @name 드롭다운 라운드 형태
 */
export const IcoDropDownRoundDown = (): React.ReactElement => {
  return <StyledIcon className="ico_dropdown_round_down"></StyledIcon>;
};

/**
 * @name 돋보기 아이콘
 */
export const IcoSearch = (): React.ReactElement => {
  return <StyledIcon className="ico_search"></StyledIcon>;
};

/**
 * @name 뒤로가기 아이콘
 */
export const IcoBack = (): React.ReactElement => {
  return <StyledIcon className="ico_back"></StyledIcon>;
};

/**
 * @name 뒤로가기 아이콘
 */
export const IcoBackWhite = (): React.ReactElement => {
  return <StyledIcon className="ico_back_white"></StyledIcon>;
};

/**
 * @name 물음표 아이콘
 */
export const IcoQuestion = (): React.ReactElement => {
  return <StyledIcon className="ico_question"></StyledIcon>;
};
/**
 * @name 물음표 아이콘
 */
export const IcoQuestionWhite = (): React.ReactElement => {
  return <StyledIcon className="ico_question_white"></StyledIcon>;
};

/**
 * @name 화살표 아이콘 흰색
 */
export const IcoArrowWhite = (): React.ReactElement => {
  return <StyledIcon className="icon_arrow_white"></StyledIcon>;
};

/**
 * @name 화살표 아이콘 백그라운드 초록
 */
export const IcoArrowBg = (): React.ReactElement => {
  return <StyledIcon className="icon_arrow_bg"></StyledIcon>;
};

/**
 * @name 필터 아이콘
 */
export const IcoFilter = (): React.ReactElement => {
  return <StyledIcon className="icon_filter"></StyledIcon>;
};

/**
 * @name 검색 리셋 아이콘
 */
export const IcoReset = (): React.ReactElement => {
  return <StyledIcon className="icon_reset"></StyledIcon>;
};

/**
 * @name 정렬순 아이콘
 */
export const IcoOrder = (): React.ReactElement => {
  return <StyledIcon className="icon_order"></StyledIcon>;
};

/**
 * @name 드롭다운 아이콘
 */
export const IcoDropDown = (): React.ReactElement => {
  return <StyledIcon className="ico_dropdown"></StyledIcon>;
};

/**
 * @name 펼치기 아이콘 (기본 형태, 아래 방향)
 */
export const IcoArrowDown = (): React.ReactElement => {
  return <StyledIcon className="ico_arrow_down"></StyledIcon>;
};

/**
 * @name 접기 아이콘 (기본 형태, 위 방향)
 */
export const IcoArrowUp = (): React.ReactElement => {
  return <StyledIcon className="ico_arrow_up"></StyledIcon>;
};

/**
 * @name 화살표 아이콘 (bar가 있는 형태, 오른쪽 방향)
 */
export const IcoArrowBarRight = (): React.ReactElement => {
  return <StyledIcon className="ico_arrow_bar_right"></StyledIcon>;
};

/**
 * @name 앱업데이트 아이콘
 */
export const IcoUpdate = (): React.ReactElement => {
  return <StyledIcon className="icon_update"></StyledIcon>;
};

/**
 * @name 멈춤 아이콘
 */
export const IcoPause = (): React.ReactElement => {
  return <StyledIcon className="icon_pause"></StyledIcon>;
};

/**
 * @name 재생 아이콘
 */
export const IcoPlay = (): React.ReactElement => {
  return <StyledIcon className="icon_play"></StyledIcon>;
};

/**
 * @name 카트 아이콘
 */
export const IcoCart = (): React.ReactElement => {
  return <StyledIcon className="icon_cart"></StyledIcon>;
};

/**
 * @name 쌍따옴표 아이콘
 */
export const IcoQuotes = (): React.ReactElement => {
  return <StyledIcon className="icon_quotes"></StyledIcon>;
};

/**
 * @name 메인배너 광고 아이콘
 */
export const IcoAdvertise = (): React.ReactElement => {
  return <StyledIcon className="icon_advertise"></StyledIcon>;
};

/**
 * @name 전화 아이콘
 */
export const IcoCall = (): React.ReactElement => {
  return <StyledIcon className="icon_call"></StyledIcon>;
};

/**
 * @name 푸터 홈 아이콘
 */
export const IcoFooterHome = (props: {
  isActive: boolean;
}): React.ReactElement => {
  return (
    <StyledIcon
      className={`icon_footer_home ${props.isActive && 'active'}`}
    ></StyledIcon>
  );
};

/**
 * @name 푸터 푸드페어링 아이콘
 */
export const IcoFooterFoodPairing = (props: {
  isActive: boolean;
}): React.ReactElement => {
  return (
    <StyledIcon
      className={`icon_footer_foodpairing ${props.isActive && 'active'}`}
    ></StyledIcon>
  );
};

/**
 * @name 푸터 검색 아이콘
 */
export const IcoFooterSearch = (props: {
  isActive: boolean;
}): React.ReactElement => {
  return (
    <StyledIcon
      className={`icon_footer_search ${props.isActive && 'active'}`}
    ></StyledIcon>
  );
};

/**
 * @name 푸터 마이벙커 아이콘
 */
export const IcoFooterMybunker = (props: {
  isActive: boolean;
}): React.ReactElement => {
  return (
    <StyledIcon
      className={`icon_footer_mybunker ${props.isActive && 'active'}`}
    ></StyledIcon>
  );
};

/**
 * @name 푸터 마이페이지 아이콘
 */
export const IcoFooterMypage = (props: {
  isActive: boolean;
}): React.ReactElement => {
  return (
    <StyledIcon
      className={`icon_footer_mypage ${props.isActive && 'active'}`}
    ></StyledIcon>
  );
};

/**
 * @name 지도표시 아이콘
 */
export const IcoMarker = (props: {
  color: 'white' | 'purple' | 'red';
}): React.ReactElement => {
  return <StyledIcon className={`icon_marker ${props.color}`}></StyledIcon>;
};

/**
 * @name 복사하기 아이콘
 */
export const IcoCopy = (): React.ReactElement => {
  return <StyledIcon className={`icon_copy`}></StyledIcon>;
};

/**
 * @name 테이스팅노트 현재 상품스타일 표시 아이콘
 */
export const IcoTriangle = (): React.ReactElement => {
  return <StyledIcon className={`ico_triangle`}></StyledIcon>;
};

/**
 * @name QR코드  아이콘
 */
export const IcoQrcode = (): React.ReactElement => {
  return <StyledIcon className={`ico_qrcode`}></StyledIcon>;
};

/**
 * @name 화살표 아이콘
 */
export const IcoNext = (): React.ReactElement => {
  return <StyledIcon className="ico_next"></StyledIcon>;
};

/**
 * @name 화살표 아이콘 Bold
 */
export const IcoNextBold = (): React.ReactElement => {
  return <StyledIcon className="ico_next_bold"></StyledIcon>;
};

/**
 * @name 서브헤더 바코드 아이콘
 */
export const IcoBarcodeSubheader = (): React.ReactElement => {
  return <StyledIcon className="icon_barcode"></StyledIcon>;
};

/**
 * @name 서브헤더 바코드 아이콘(흰색)
 */
export const IcoBarcodeSubheaderWhite = (): React.ReactElement => {
  return <StyledIcon className="icon_barcode_w"></StyledIcon>;
};

/**
 * @name 서브헤더 알림 아이콘
 */
export const IconAlramSubheader = (): React.ReactElement => {
  return <StyledIcon className="icon_alram_subheader"></StyledIcon>;
};

/**
 * @name 서브헤더 알림 아이콘(흰색)
 */
export const IconAlramSubheaderWhite = (): React.ReactElement => {
  return <StyledIcon className="icon_alram_subheader_w"></StyledIcon>;
};

/**
 * @name 서브헤더 알림 아이콘(흰색)
 */
export const IconClock = (): React.ReactElement => {
  return <StyledIcon className="icon_clock"></StyledIcon>;
};

/**
 * @name 테이스팅노트 작성 아이콘
 */
export const IcoWrite = (): React.ReactElement => {
  return <StyledIcon className="ico_write"></StyledIcon>;
};

/**
 * @name 마이페이지 등급안내 느낌표
 */
export const IcoGradeNotice = (): React.ReactElement => {
  return <StyledIcon className="ico_grade_notice"></StyledIcon>;
};

/**
 * @name 와인타입별 아이콘 스타일 컴포넌트
 */
export const IcoWine = (props: { wineType: string }): React.ReactElement => {
  const { wineType } = props;

  /* 
    034006001	레드와인
    034006002	스파클링와인
    034006003	로제와인
    034006004	화이트와인
    034006005	논알콜와인
    034006007	내츄럴와인
    034006008 주정강화와인
  */
  const className =
    wineType === '034006001'
      ? 'red'
      : wineType === '034006002'
      ? 'sparkling'
      : wineType === '034006003'
      ? 'rose'
      : wineType === '034006004'
      ? 'white'
      : wineType === '034006005'
      ? 'none'
      : wineType === '034006007'
      ? 'natural'
      : wineType === '034006008'
      ? 'fortified'
      : '';
  return <StyledIcon className={`ico_wine ${className}`}></StyledIcon>;
};

/**
 * @name 양주타입별 아이콘 스타일 컴포넌트
 */
export const IcoLiquor = (props: {
  liquorType: string;
}): React.ReactElement => {
  const { liquorType } = props;

  const className =
    liquorType === '034005009'
      ? 'singlemalt_whisky'
      : liquorType === '034005001'
      ? 'blended_whisky'
      : liquorType === '034005010'
      ? 'bourbon_whisky'
      : liquorType === '034005011'
      ? 'japan_whisky'
      : liquorType === '034005002'
      ? 'brandy'
      : liquorType === '034005013'
      ? 'rum'
      : liquorType === '034005014'
      ? 'gin'
      : liquorType === '034005004'
      ? 'vodka'
      : liquorType === '034005012'
      ? 'tequila'
      : liquorType === '034005005'
      ? 'sake'
      : liquorType === '034005006'
      ? 'baijiu'
      : liquorType === '034005003'
      ? 'liqueur'
      : liquorType === '034005007'
      ? 'gift'
      : liquorType === '034005008'
      ? 'flatprice'
      : liquorType === '034005015'
      ? 'miniature'
      : '';
  return <StyledIcon className={`ico_liquor ${className}`}></StyledIcon>;
};

/**
 * @name 이벤트 상태 뱃지
 */
export const IcoEventBadge = (props: {
  eventStatusCode: 'ING' | 'BEFORE' | 'END';
}): React.ReactElement => {
  return <StyledIconEventBadge eventStatusCode={props.eventStatusCode} />;
};

/**
 * @name 테이스팅클래스 상태 뱃지
 */
export const IcoTastingClassBadge = (props: {
  eventStatusCode: 'CLASS_SOON' | 'CLASS_ING' | 'CLASS_END';
}): React.ReactElement => {
  return (
    <StyledIconTastingClassBadge eventStatusCode={props.eventStatusCode} />
  );
};

/**
 * @name 와인봉투 테이스팅노트 구매 아이콘
 */
export const IcoShoppingBag = (): React.ReactElement => {
  return <StyledIcon className="ico_shoppingbag"></StyledIcon>;
};

/**
 * @name 테이스팅노트 별점 아이콘 흰색
 */
export const IcoStarPoint = (props: {
  isActive: boolean;
}): React.ReactElement => {
  return (
    <StyledIcon
      className={`${props.isActive ? 'ico_starpoint_active' : 'ico_starpoint'}`}
    ></StyledIcon>
  );
};

/**
 * @name 테이스팅노트 별점 아이콘 초록색
 */
export const IcoStarPoint02 = (props: {
  isActive: boolean;
}): React.ReactElement => {
  return (
    <StyledIcon
      className={`${
        props.isActive ? 'ico_starpoint_green_active' : 'ico_starpoint_green'
      }`}
    ></StyledIcon>
  );
};

/**
 * @name 마이벙커담기 아이콘
 */
export const IcoFavorites = (props: {
  product: TBottleProduct;
}): React.ReactElement => {
  return (
    <StyledIcon
      className={`${
        Number(props.product.bnkr_cnt) > 0
          ? 'icon_favorites_on'
          : 'icon_favorites_off'
      }`}
    ></StyledIcon>
  );
};

/**
 * 강조하기 빨간점
 * @returns
 */
export const IcoRedDot = (): React.ReactElement => {
  return <StyledIconRedDot />;
};

/**
 * @name 마이벙커담기 아이콘(상품상세)
 */
export const IcoBookMark = (props: {
  isMyBucker: string;
}): React.ReactElement => {
  return (
    <StyledIcon
      className={`ico_bookmark ${props.isMyBucker === 'Y' ? 'active' : ''}`}
    ></StyledIcon>
  );
};

/**
 * 재입고알림 버튼
 * @returns
 */
export const IcoAlramRestock = (): React.ReactElement => {
  return <StyledIcon className={`ico_alram_restock`}></StyledIcon>;
};

/**
 * 페이징 로딩중 아이콘
 * @returns
 */
export const IcoPageLoading = (): React.ReactElement => {
  return <StyledIcon className={`icon_page_loading`}></StyledIcon>;
};
