import styled, { css } from 'styled-components';
import { rem } from 'src/styles/function';
import { thecarmenFont } from 'src/styles/font';

/**
 * @name 기본 버튼
 * @param variant 버튼 스타일
 * @param buttonColor 버튼 색상
 */
export const StyledButton = styled.button<{ variant; buttonColor }>`
  width: 100%;
  height: ${rem(48)};
  text-align: center;
  ${thecarmenFont('regular')}
  font-size:${rem(15)};

  ${props => {
    switch (props.variant) {
      case 'outlined':
        return css`
          border: 1px solid;
          border-color: ${props.buttonColor};
          background-color: ${({ theme }) => theme.colors.white};
          color: ${props.buttonColor};
        `;
      case 'contained':
        return css`
          background-color: ${props.buttonColor};
          color: ${({ theme }) => theme.colors.white};
        `;
      case 'text':
        return css`
          color: ${props.buttonColor};
        `;
      case 'switch':
        return css`
          position: absolute;
          top: 50%;
          right: 0;
          transform: translate(0%, -50%);
          width: ${rem(48)};
          height: ${rem(20)};
          background-color: ${({ theme }) => theme.colors.white};
          border: 1px solid ${props.buttonColor};
          border-radius: ${rem(10)};
          text-align: left;
          padding-left: ${rem(2.5)};
          span {
            display: inline-block;
            width: ${rem(16)};
            height: ${rem(16)};
            background-color: ${props.buttonColor};
            border-radius: ${rem(8)};
            vertical-align: middle;
          }
        `;
      default:
        return css``;
    }
  }}
`;

/**
 * @name 기본 버튼 Wrapper (2개 이상일 경우 사용!!)
 * @prop count = 버튼 갯수
 */
export const StyledButtonGroup = styled.div<{ count: number }>`
  display: flex;
  justify-content: space-around;
  button {
    // width: ${props => 100 / props.count - 2}% !important;
  }
`;

/**
 * 하단 고정 버튼 wrapper
 */
export const StyledButtonFixedBottom = styled.div`
  display: flex;
  justify-content: space-around;
  position: fixed;
  width: 100%;
  z-index: 10;
  bottom: ${rem(56)};
  height: ${rem(56)};
`;
