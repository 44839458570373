import React, { useEffect } from 'react';
import { StyledInspection } from './styles';

import { IMAGE_ROOT } from 'src/utils/constants';

import useSelectorEqual from 'src/hooks/useSelectorEqual';
import { RootState } from 'src/store/reducers';
//import { gaEvent, gaPageView } from 'src/utils/ga';
import { Button } from '../Button';
import {
  bridgeBarcodeClose,
  bridgeBarcodeOpen,
  bridgeQuit,
} from 'src/utils/bridge';
import JsBarcode from 'jsbarcode';
import { thejamsilFont } from 'src/styles/font';

function InspectionAppcardPopup(): React.ReactElement {
  const [userAppCardInfoState] = useSelectorEqual((state: RootState) => [
    state.user.userAppCardInfoState,
  ]);

  const handleClickQuit = () => {
    // gaEvent('앱종료', '', '', '시스템 점검안내(앱카드) 팝업');

    bridgeBarcodeClose(); // 화면 밝기 원복
    bridgeQuit();
  };

  // 바코드 생성
  useEffect(() => {
    if (!userAppCardInfoState.member_app_card_no) return;
    const barcode = userAppCardInfoState.member_app_card_no;
    const barcodeString =
      barcode.substring(0, 4) +
      '  ' +
      barcode.substring(4, 8) +
      '  ' +
      barcode.substring(8, 12) +
      '  ' +
      barcode.substring(12, 16);

    window.setTimeout(function () {
      JsBarcode('#barcode-img', barcode, {
        background: '#ffffff',
        text: barcodeString,
        height: 50,
        textAlign: 'center',
        textMargin: 15,
        fontSize: 15,
        font: `${thejamsilFont('light')}`,
      });
    }, 0);
  }, [userAppCardInfoState.member_app_card_no]);

  useEffect(() => {
    // 화면 밝기 최대
    bridgeBarcodeOpen();
  }, []);

  // useEffect(() => {
  //   gaPageView(location.pathname, '', `시스템 점검안내(앱카드) 팝업`);
  // }, []);

  return (
    <StyledInspection>
      <div className="title">
        <div className="grid">
          <div className="grid_text">
            <div>롯데마트GO</div>
            <h1>시스템 점검 안내</h1>
          </div>
          <div>
            <img
              src={`${IMAGE_ROOT}/default/inspection.png`}
              alt="점검안내 이미지"
            />
          </div>
        </div>
        <div className="grid_desc">
          <h3>서비스 이용에 불편을 드려 죄송합니다.</h3>

          <p>
            안정적인 서비스 제공을 위해 <br />
            시스템 장비 교체 작업 진행중입니다.
            <br />
            롯데마트GO 서비스가 일시 중단됨을 안내드립니다.
          </p>
        </div>
      </div>
      <div className="contents">
        <ul>
          <li>
            <div className="barcode">
              <svg id="barcode-img"></svg>
            </div>
          </li>
          <li>
            <p>
              위 바코드로 <span>L.POINT 적립</span>,<br />
              <span>쿠폰자동할인</span>을 하실 수 있습니다.
            </p>
          </li>
        </ul>
      </div>
      <Button text="앱종료" onClick={handleClickQuit}></Button>
      <div className="logo">
        <img src={`${IMAGE_ROOT}/default/logo_martgo.png`} alt="롯데마트GO" />
      </div>
    </StyledInspection>
  );
}

export default InspectionAppcardPopup;
