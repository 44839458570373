import React, { useEffect } from 'react';
import { RootState } from 'src/store/reducers';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { history } from 'src/hooks/history';
import useSelectorEqual from 'src/hooks/useSelectorEqual';

import { E_POPUP_STATE } from 'src/utils/enum';
import {
  actionCloseAlertPopup,
  actionCloseBarcodePopup,
  actionCloseConfirmPopup,
  actionCloseTermsPouup,
} from 'src/redux/actions/appActions';

import Terms from '../Terms';
import Portal from 'src/components/Portal';
import ConfirmPopup from 'src/components/Popup/ConfirmPopup';
import AlertPopup from 'src/components/Popup/AlertPopup';
import LoadingPopup from 'src/components/Popup/LoadingPopup';
import { bridgeBarcodeClose } from 'src/utils/bridge';

import MartSettingPopup from 'src/components/Popup/MartSettingPopup';
import InspectionAppcardPopup from 'src/components/Popup/InspectionAppcardPopup';
import BarcodePopup from 'src/components/Popup/BarcodePopup';
import StoreInfoPopup from 'src/components/Popup/StoreInfoPopup';

function ProtalControl(): React.ReactElement {
  const dispatch = useDispatch();
  const location = useLocation();

  const [
    confirmPopupState,
    alertPopupState,
    termsPopupState,
    isOpenLoadingPopup,
    martSettingPopupState,
    isOpenInspectionAppcardPopup,
    isOpenBarcodePopupState,
    storeInfoPopupState,
  ] = useSelectorEqual((state: RootState) => [
    state.app.confirmPopupState,
    state.app.alertPopupState,
    state.app.termsPopupState,
    state.app.isOpenLoadingPopup,
    state.app.martSettingPopupState,
    state.app.isOpenInspectionAppcardPopup,
    state.app.isOpenBarcodePopupState,
    state.app.storeInfoPopupState,
  ]);

  //뒤로가기 시 팝업 닫기
  useEffect(() => {
    const listenBackEvent = (): any => {
      if (
        alertPopupState.isOpen ||
        confirmPopupState.isOpen ||
        termsPopupState.isOpen ||
        isOpenBarcodePopupState === E_POPUP_STATE.OPEN
      ) {
        dispatch(actionCloseAlertPopup());
        dispatch(actionCloseConfirmPopup());
        dispatch(actionCloseTermsPouup());
        dispatch(actionCloseBarcodePopup());
        window.history.pushState(null, '', location.pathname + location.search);
        bridgeBarcodeClose();
      }
    };

    const unlistenHistoryEvent = history.listen(({ action }) => {
      if (action === 'POP') {
        listenBackEvent();
      }
    });

    return () => unlistenHistoryEvent();
  }, [
    dispatch,
    location,
    alertPopupState.isOpen,
    confirmPopupState.isOpen,
    termsPopupState.isOpen,
    isOpenBarcodePopupState,
  ]);

  return (
    <>
      {confirmPopupState.isOpen && (
        <Portal id="confirmPopup" isOpen={confirmPopupState.isOpen}>
          <ConfirmPopup confirmPopupState={confirmPopupState} />
        </Portal>
      )}

      {alertPopupState.isOpen && (
        <Portal id="alertPopup" isOpen={alertPopupState.isOpen}>
          <AlertPopup alertPopupState={alertPopupState} />
        </Portal>
      )}

      {termsPopupState.isOpen && (
        <Portal id="termsPopup" isOpen={termsPopupState.isOpen}>
          <Terms termsPopupState={termsPopupState} />
        </Portal>
      )}

      {isOpenLoadingPopup === E_POPUP_STATE.OPEN && (
        <Portal
          id="loadingPopup"
          isOpen={isOpenLoadingPopup === E_POPUP_STATE.OPEN}
        >
          <LoadingPopup />
        </Portal>
      )}
      {/* <Portal id="loadingPopup" isOpen={true}>
        <LoadingPopup />
      </Portal> */}

      {martSettingPopupState.isOpen === E_POPUP_STATE.OPEN && (
        <Portal
          id="martSettingPopup"
          isOpen={martSettingPopupState.isOpen === E_POPUP_STATE.OPEN}
        >
          <MartSettingPopup martSettingPopupState={martSettingPopupState} />
        </Portal>
      )}

      {isOpenInspectionAppcardPopup === E_POPUP_STATE.OPEN && (
        <Portal
          id="inspectionAppcardPopup"
          isOpen={isOpenInspectionAppcardPopup === E_POPUP_STATE.OPEN}
        >
          <InspectionAppcardPopup />
        </Portal>
      )}

      {isOpenBarcodePopupState === E_POPUP_STATE.OPEN && (
        <Portal
          id="barcodePopup"
          isOpen={isOpenBarcodePopupState === E_POPUP_STATE.OPEN}
        >
          <BarcodePopup isOpen={isOpenBarcodePopupState} />
        </Portal>
      )}

      {storeInfoPopupState.isOpen === E_POPUP_STATE.OPEN && (
        <Portal
          id="storeInfoPopup"
          isOpen={storeInfoPopupState.isOpen === E_POPUP_STATE.OPEN}
        >
          <StoreInfoPopup storeInfoPopupState={storeInfoPopupState} />
        </Portal>
      )}
    </>
  );
}

export default ProtalControl;
