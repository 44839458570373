import { DefaultTheme } from 'styled-components';

/**
 * @name 기본색상 선언
 */
const colors = {
  black: '#000',
  black_line: '#252525',
  white: '#fff',
  red: '#d74728',
  bottle_green: '#00484d',
  gray_bg: '#f4f4f4',
  gray_txt: '#727171',
  gray_line: '#dcdddd',
  gray_btn_bg: '#898989',
  gray_btn_txt: '#dcdddd',
  gray_product_divider: '#252525',
  gray_line_gap: '#f9f9f9',

  gray: '#898989',
  gray01: '#cccccc', //폰트 연한 gray
  gray02: '#999999', //폰트 중간 gray
  gray03: '#666666', //폰트 진한 gray
};

export type ColorsTypes = typeof colors;

export const theme: DefaultTheme = {
  colors,
};
