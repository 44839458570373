import React from 'react';
import CenteredPopupWrapper from './components/CenteredPopupWrapper';
import { TAlertPopupProps } from 'src/redux/types/appTypes';
import { StyledAlertPopupContent } from './styles';
import { Button } from '../Button';
import Dim from '../Dim';

function AlertPopup(props: {
  alertPopupState: TAlertPopupProps;
}): React.ReactElement {
  const { onClose, children, isOpen } = props.alertPopupState;
  return (
    <>
      <Dim zIndex={300} isDisplay={isOpen} />
      <CenteredPopupWrapper>
        <StyledAlertPopupContent>{children}</StyledAlertPopupContent>
        <Button
          onClick={onClose}
          text="확인"
          variant="contained"
          buttonColor="bottle_green"
        />
      </CenteredPopupWrapper>
    </>
  );
}

export default AlertPopup;
